import CrudStore from '~/store/modules/common/crudStore'
var crud = new CrudStore('fileManager', null, true)

export const state = () => ({
  ...crud.state,
  currentPath: '',
  // document structure cache
  documentMap: {},
  searchTerms: '',
  openFolder: '',
  uploadProgress: {}, // key: 0 to 1
  downloadProgress: {} // key: 0 to 1
})

export const getters = {
  ...crud.getters,
  getUploadProgress(state) { return state.uploadProgress },
  getDownloadProgress(state) { return state.downloadProgress },
  getDocuments(state) {
    return state.documentMap
  },
  getPath: (state) => (document) => {
    return $nuxt.$utils.getLodashPath(typeof document === 'object' && 'path' in document ? document.path : document)
  },
  getLabel: (state) => (id) => {},
  getMetadata: (state) => (obj) => {
    const userMetadata = {}
    if (obj) {
      Object.entries(obj).forEach(([metaKey, metaVal]) => {
        if (typeof metaVal !== 'string') {
          userMetadata[metaKey] = metaVal
          return
        }

        if (metaKey === 'permissions') metaVal = metaVal.toLowerCase()
        if (metaVal === 'null' || !metaVal) userMetadata[metaKey] = null

        if (metaVal.startsWith('[')) {
          userMetadata[metaKey] = JSON.parse(metaVal) || []
        } else if (metaVal.startsWith('{')) {
          userMetadata[metaKey] = JSON.parse(metaVal) || {}
        } else {
          userMetadata[metaKey] = metaVal
        }
      })
    }

    return userMetadata
  },
  getAllFiles: (state, getters, rootState, rootGetters) => {
    if (rootState.auxiliary.s3.objectsPending) return []
    let objectArray = []

    const docs = _.cloneDeep(_.get(rootState, 'auxiliary.documents.documents', []))
    docs.forEach((doc) => {
      let path = doc.path.split('/')
      path = path.filter((el, i) => i > 1).join('/')

      _.set(doc, 'metadata.permissions', _.get(doc, 'metadata.permissions', '').toLowerCase())

      objectArray.push({
        ...doc,
        ...doc.metadata,
        lastModified: doc.dateLastModified,
        path,
      })
    })

    return objectArray
  },
  getFileView: (state, getters, rootState, rootGetters) => {
    // Construct _documents object with each key as file/folder name
    if (rootState.auxiliary.s3.objectsPending) return []
    let objectArray = []
    const currentPath = _.cloneDeep(state.currentPath)

    const view = currentPath ? _.get(state.documentMap, $nuxt.$utils.getLodashPath(currentPath)) : state.documentMap
    if (!view) return []

    Object.entries(view).map(([k, document]) => {
      if (k === '_metadata') return
      let originalFileName = k.replace('/', '')

      const metadata = document?._metadata ? document._metadata : { type: 'folder', path: '', size: '', lastModified: '', permissions: '', fileName: '', dateUploaded: '' }

      // Attempt to resolve file name, or use the key without the extension
      let resolvedFileName = $nuxt.$utils.getDisplayName(originalFileName)
      let fileName = resolvedFileName !== 'Unnamed' ? resolvedFileName : originalFileName
      const names = fileName.split('.')
      if (names.length > 1) names.pop() // pop off extension
      fileName = names.join('.')

      const userMetadata = getters.getMetadata(metadata?.object)

      // match to documents by the path
      const correspondingDocument = rootGetters['auxiliary/documents/getByPath'](metadata.fullPath)

      if (fileName) {
        objectArray.push({
          type: metadata.type,
          path: metadata.path,
          url: metadata.fullPath,
          size: metadata.size,
          lastModified: metadata.lastModified,
          fileName,
          metadata: userMetadata,
          id: correspondingDocument?.id ?? $nuxt.$uuid(),
          dateUploaded: correspondingDocument?.dateUploaded || '',
          tags: correspondingDocument?.tags ?? [],
          entities: correspondingDocument?.entities ?? [],
        })
      }
    })
    return objectArray
  },
  searchFiles: (state, getters, rootState, rootGetters) => {
    if (Array.isArray(rootState.auxiliary.documents.documents) && rootState.auxiliary.documents.documents.length === 0) return []

    return rootState.auxiliary.documents.documents
      .map((document) => {
        let userMetadata = getters.getMetadata(document.metadata)
        const fileName = $nuxt.$utils.isUUID(document.fileName.replace('/', '')) ? $nuxt.$utils.getDisplayName(document.fileName.replace('/', '')) : document.type === 'folder' ? document.fileName.replace('/', '') : document.fileName.replace(`.${document.type}`, '')

        return {
          ...document,
          ...userMetadata,
          fileName,
          metadata: userMetadata,
          lastModified: document.dateLastModified,
          dateUploaded: document.dateUploaded,
          path: document.path.split(document.entityGroupId + '/')[1],
        }
      })
      .filter((document) => {
        // Result array cannot contain false
        const resultArray = []

        for (let i = 0; i < state.searchTerms.trim().split(' ').length; i++) {
          let term = state.searchTerms.trim().split(' ')[i].trim().toLowerCase()

          if (document.fileName.toLowerCase().includes(term)) resultArray[i] = true

          // Search among tags, entities
          if (document.tags) {
            for (let tag of document.tags) {
              tag = rootGetters['modules/configurationHub/getTag'](tag)
              if (tag?.name?.toLowerCase()?.includes(term)) resultArray[i] = true
            }
          }
          if (document.entities) {
            for (let entityId of document.entities) {
              const entityName = $nuxt.$utils.getDisplayName(entityId)
              if (entityName.toLowerCase().includes(term)) resultArray[i] = true
            }
          }

          // If no matches were found for this term, add false
          if (resultArray[i] !== true) {
            resultArray[i] = false
          }
        }

        return !resultArray.includes(false)
      })
  },
}

export const mutations = {
  ...crud.mutations,
  setUploadProgress(state, { key, progress }) {
    state.uploadProgress[key] = progress
  },
  setDownloadProgress(state, { key, progress }) {
    state.downloadProgress[key] = progress
  },
  unsetUploadProgress(state, key) {
    delete state.uploadProgress[key]
  },
  unsetDownloadProgress(state, key) {
    delete state.downloadProgress[key]
  },
  setOpenFolder(state, folder) {
    state.openFolder = folder
  },
  setCurrentPath(state, payload) {
    state.currentPath = payload.replace(/^\.[\w\d-]+\//g, '')
    this.commit('auxiliary/documents/setPrefix', state.currentPath, { root: true })
  },
  setSearchTerms(state, payload) {
    state.searchTerms = payload
  },
  refreshDocumentMap(state, payload) {
    // Refresh on root folder
    if (!payload) state.documentMap = {}
    else {
      // Refresh a subdirectory
      _.set(state.documentMap, this.$utils.getLodashPath(payload), { _metadata: _.get(state.documentMap, this.$utils.getLodashPath(payload))?._metadata || null })
    }
  },
  setDocumentMap(state, { path, value }) {
    path = this.$utils.getLodashPath(path)
    let map = _.cloneDeep(state.documentMap)
    const currentState = _.cloneDeep(state.documentMap)
    let pathsSplitByDepth = path.split(new RegExp('/[.[]'))

    // Add the bracket ([) to the start of each string as we just split by it
    pathsSplitByDepth = pathsSplitByDepth.map((_path) => {
      if (_path.endsWith(']') && !path.startsWith('[')) return '[' + _path
      else return _path
    })

    let joinedPaths = ''
    let joinedPathsReal = ''
    let parentPermissions = null

    // Fill out a default _metadata key for the directories which have not been initialized
    if (pathsSplitByDepth.length > 1) {
      for (let i = 0; i < pathsSplitByDepth.length; i++) {
        const thisFolder = pathsSplitByDepth[i].replace('/', '')

        const parentDirectory = _.get(map, joinedPaths, null)
        const needsRefresh = _.get(currentState, joinedPaths, null) === null

        joinedPaths += (joinedPaths && !thisFolder.startsWith('[') ? '.' : '') + thisFolder + '/'
        // Do I need this? who knows
        // joinedPathsReal += thisFolder.startsWith('[') ? thisFolder.substring(2, thisFolder.length - 2) : thisFolder + '/'
        joinedPathsReal += thisFolder + '/'

        const subDirectory = _.get(map, joinedPaths)

        // Get the permissions of the parent folder
        if (!parentPermissions) {
          parentPermissions = parentDirectory ? _.get(parentDirectory, '_metadata?.object?.permissions', {}) : _.get(subDirectory, '_metadata?.object?.permissions', {})
        }

        // map = _.set(map, joinedPaths + '._metadata', parentPermissions ? { type: 'folder', path: joinedPathsReal, object: { permissions: parentPermissions } } : { type: 'folder', path: joinedPathsReal })
        map = _.set(map, joinedPaths + '._metadata', { type: 'folder', path: joinedPathsReal, shouldRefresh: true })
      }
    }

    // if (parentPermissions && value?._metadata?.type === 'folder') value = { ...value, _metadata: { ...value._metadata, object: { ...value._metadata.object, permissions: parentPermissions } } }

    map = _.set(map, path, value)
    state.documentMap = map
  },
  /*
    Transfer the document between paths in documentMap
  */
  transfer(state, { oldPath, document, mode = 'cut' }) {
    let map = _.cloneDeep(state.documentMap)
    const oldParts = this.$utils.retrievePathParts(oldPath)
    oldPath = this.$utils.getLodashPath(oldParts[2] + oldParts[3] + '.' + oldParts[4])

    // Retrieve a copy of the old node
    const fileNode = _.cloneDeep(_.get(map, oldPath, null))
    if (fileNode == null) return

    const newParts = this.$utils.retrievePathParts(document.path)
    const newPath = this.$utils.getLodashPath(newParts[2] + newParts[3] + '.' + newParts[4])

    // Alter new file node
    fileNode._metadata.fullPath = document.path
    fileNode._metadata.lastModified = document.dateLastModified
    fileNode._metadata.path = oldParts[2] + oldParts[3] + '.' + oldParts[4]

    // Remove old FIRST, in case the path didn't actually change
    if (mode == 'cut') map = _.omit(map, oldPath)

    // Add new node to document map    
    _.set(map, newPath, fileNode)

    state.documentMap = map
  },
  /**
   * Delete node(s) from the documentMap
   */
  deletePaths(state, paths) {
    if (!Array.isArray(paths)) paths = [paths]
    let map = _.cloneDeep(state.documentMap)

    paths = paths.map(path => {
      const [org, entityGroup, relPath, fileName, extension, folderName] = this.$utils.retrievePathParts(path)
      const objectName = folderName ? folderName + '/' : fileName + '.' + extension
      return this.$utils.getLodashPath(relPath + objectName)
    })
    state.documentMap = _.omit(map, paths)
  },
  /**
   * Add a new node to the file view map, from webstomp message data
   */
  addNode(state, node) {
    const [orgId, entityGroupId, relPath, fileName, extension, folderName] = this.$utils.retrievePathParts(node.fullPath)
    let map = _.cloneDeep(state.documentMap)
    const objectName = folderName ? folderName + '/' : fileName + '.' + extension
    const path = this.$utils.getLodashPath(relPath + objectName)
    _.set(map, path, { _metadata: node })
    state.documentMap = map
  }
}

export const actions = {
  ...crud.actions,
  debouncedSetCurrentPath: _.debounce(
    function ({ state, commit }) {
      if (state.openFolder !== '') {
        commit('setCurrentPath', state.openFolder)
        commit('setOpenFolder', '')
      }
    },
    300,
    { trailing: true }
  ),
  async getDocuments({ state, commit, dispatch, rootState }, payload) {
    const currentPath = _.clone(state.currentPath)

    if (_.get(rootState, 'auxiliary.s3.objectsPending', true)) return

    // A search is active - refresh Documents array from backend
    if (state.searchTerms) {
      await dispatch('auxiliary/documents/getDocumentsByEntityGroup', true, { root: true })
      return
    }

    // Return the document map if on root folder and if there are items in document map
    if (!payload && Object.keys(state.documentMap).length !== 0) {
      return await commit('auxiliary/s3/setObjects', { _documents: state.documentMap }, { root: true })
    }

    const existingStructure = _.get(state.documentMap, this.$utils.getLodashPath(currentPath))

    if (!existingStructure?._metadata?.shouldRefresh) {
      // Set the documents to the existing/cached object
      if (existingStructure && Object.keys(existingStructure).length > 1) {
        await commit('auxiliary/s3/setObjects', { _documents: existingStructure }, { root: true })
        return
      }
    }

    // Make the request (could not find path in cache)
    await dispatch('auxiliary/s3/getObjects', payload, { root: true })
    const documents = _.cloneDeep(rootState.auxiliary.s3.objects)

    // Construct the map of documents
    Object.entries(documents).forEach(([k, v]) => {
      Object.entries(v).forEach(([key, val]) => {
        commit('setDocumentMap', { path: currentPath + key, value: val })
      })
    })
    dispatch('debouncedSetCurrentPath')
  },
  async refreshFileManager({ dispatch }) {
    await dispatch('auxiliary/documents/getDocumentsByEntityGroup', null, { root: true })
    await dispatch('auxiliary/s3/getFolderStructure', null, { root: true })
    await dispatch('refreshDocuments')
  },
  async refreshDocuments({ state, commit, dispatch }, payload) {
    const currentPath = _.clone(state.currentPath)
    if (!payload) {
      commit('refreshDocumentMap', currentPath)
      await dispatch('getDocuments', currentPath)
    } else {
      commit('refreshDocumentMap', payload)
      await dispatch('getDocuments', payload)
    }
  },
  async upload({ dispatch, state, commit }, { path, file, headers }) {
    if (!path.startsWith(state.currentPath)) path = state.currentPath + path
    const url = await dispatch('auxiliary/s3/generateUploadUrl', { path }, { root: true })
    if (!url) return

    const uploadAxios = this.$axios.create({ withCredentials: false })
    delete uploadAxios.defaults.headers.common['webstomp-transaction']
    delete uploadAxios.defaults.headers.common['Authorization']

    commit('unsetUploadProgress', path)
    await uploadAxios.request(url, {
      params: headers,
      method: 'PUT',
      data: file,
      onUploadProgress: (e) => {
        commit('setUploadProgress', { key: path, progress: e.loaded / e.total })
      }
    })
    setTimeout(() => { commit('unsetUploadProgress', path) }, 2000)
  },
  async download({ dispatch, state, commit }, { paths }) {
    const urls = await Promise.allSettled(paths.map((path, idx) => {
      return new Promise(async (resolve, reject) => {
        if (!path.startsWith(state.currentPath)) paths[idx] = state.currentPath + path
        const [orgId, groupId, relPath, fileName, extension] = this.$utils.retrievePathParts(path)
        const url = await dispatch('auxiliary/s3/generateDownloadUrl', { path }, { root: true })
        return resolve({ url, fileName: fileName + '.' + extension })
      })
    }))
    
    const downloadAxios = this.$axios.create({ withCredentials: false })
    delete downloadAxios.defaults.headers.common['webstomp-transaction']
    delete downloadAxios.defaults.headers.common['Authorization']

    urls.map(async ({ value, status }) => {
      if (status != 'fulfilled') return
      const { url, fileName } = value
      commit('unsetDownloadProgress', fileName)
      const { data } = await downloadAxios.request(url, {
        method: 'GET',
        responseType: 'blob',
        onDownloadProgress: (e) => {
          commit('setDownloadProgress', { key: fileName, progress: e.loaded / e.total })
        }
      })
      setTimeout(() => { commit('unsetDownloadProgress', fileName) }, 2000)

      const a = window.document.createElement('a')
      a.href = window.URL.createObjectURL(data)
      a.download = fileName
      a.click()
      window.URL.revokeObjectURL(a.href)
    })
  },
  async modifyDocument({ dispatch, commit }, { payload, documentId }) {
    const newDocument = await dispatch('auxiliary/documents/update', { id: documentId, payload }, { root: true })
    if (!newDocument) return
    commit('transfer', { document: newDocument, oldPath: payload.url, mode: 'cut' })
    return newDocument
  },
  async transferDocument({ commit, dispatch }, { document, path, cut = true }) {
    const newDocument = await dispatch('auxiliary/documents/transfer', { id: document.id, payload: { newRelativePath: path, path: document.url, cut } }, { root: true })
    if (!newDocument) return

    // Do a local transfer to reflect the new path of the document
    commit('transfer', { document: newDocument, oldPath: document.url, mode: cut ? 'cut' : 'copy' })
    return newDocument
  },
  async deleteDocuments({ dispatch, commit }, payload) {
    if (!Array.isArray(payload)) payload = [payload]

    const paths = payload.map((el) => el.url)
    await dispatch('auxiliary/s3/delete', paths, { root: true })
    commit('deletePaths', paths)
  },
  async deleteIfEmpty({ state, commit, dispatch }, payload) {
    try {
      const { status } = await dispatch('auxiliary/s3/deleteIfEmpty', payload, { root: true })
      if (status === 200) {
        // Delete was successful
        commit('deletePaths', payload)
        return true
      } else {
        // unsuccessful
        return false
      }
    } catch (e) {
      console.warn('deleteIfEmpty fileManager failed', e)
      return false
    }
  },
  async forceDelete({ state, commit, dispatch }, payload) {
    try {
      const { status } = await dispatch('auxiliary/s3/forceDelete', payload, { root: true })
      if (status === 200) {
        // Delete was successful
        await dispatch('refreshDocuments')
        return true
      } else {
        // unsuccessful
        return false
      }
    } catch (e) {
      console.warn('forceDelete fileManager failed', e)
      return false
    }
  },
  async onMessage({ state, commit, dispatch, rootGetters }, { stompMessage }) {
    const { data: objectDto, eventName } = stompMessage
    const { document } = objectDto
    const parts = this.$utils.retrievePathParts(objectDto.fullPath)
    if (!parts) return
    let [orgId, entityGroupId, relativePath, fileName, extension, folderName] = parts

    // We only care about events for the selected entity group, or no entity group if none selected
    let rootPrefix = rootGetters['auxiliary/documents/getRootPrefix']
    if (rootPrefix) rootPrefix = `.${rootPrefix}`
    if (!entityGroupId) entityGroupId = ''
    if (entityGroupId != rootPrefix) return

    if (document) {
      commit('auxiliary/documents/putDocuments', document, { root: true })
    }

    switch (eventName) {
      case 'DeleteObject':
        commit('deletePaths', objectDto.fullPath)
        break
      case 'CopyObject':
      case 'PutObject':
        commit('addNode', objectDto)

        if (objectDto.fullPath.endsWith('/')) {
          // Ensure the treeview is updated
          await dispatch('auxiliary/s3/getFolderStructure', null, { root: true })
        }
        break
    }
  },
}