import _ from 'lodash'
export default class {
  constructor(endpoint, typeSelections, singlePage, organisation) {
    /**
     * @type {import('../../../types/store').CrudState}
     */
    this.state = {
      staleData: true,
      allItems: [],
      panelGroupField: 'type',
      endpoint: endpoint,
      typeSelections: typeSelections,
      singlePage: singlePage,
      organisation: organisation,
      pendingCreate: false,

      editMode: false,

      // Debounce fetch for webstomp
      idsToFetch: [],
      debounceFetchIds: null,
      topBarButtons: [],
    }

    /**
     * @type { import('../../../types/store').Getters<import('../../../types/store').CrudState> }
     */
    this.getters = {
      isEditMode(state) {
        return state.editMode === true
      },
      isSinglePage(state) {
        return state.singlePage === true
      },
      isOrganisationWide(state) {
        return state.organisation === true
      },
      getObject: (state, getters, rootState, rootGetters) => (id) => {
        let found = null
        if (!_.isNil(id)) {
          found = state.allItems.find((el) => el.id === id)
          if (_.isNil(found)) found = rootGetters[`application/globalSearch/getCacheObject`](id) // Check cache for ID
        }
        if (!_.isNil(found)) return found
        // If not found and it is a single page module, try match on groupId instead
        const selectedGroupId = rootState.modules?.entityGroups?.selectedEntityGroup?.id
        if (selectedGroupId && getters['isSinglePage']) return state.allItems.find(({ entityGroupId, id }) => entityGroupId === selectedGroupId || id === selectedGroupId)
        return null
      },
      getObjects: (state) => (ids) => {
        return state.allItems.filter((el) => ids.includes(el.id))
      },
      getCurrentGroupObject: (state, getters) => (id) => {
        return getters['getItems'].find((el) => el.id === id)
      },
      getItems(state, getters, rootState) {
        let items = []
        if (getters['isOrganisationWide']) {
          items = getters['getAllItems'].filter((el) => !el.deleted || el.deleted === 'ARCHIVED')
        } else {
          const selectedGroupId = rootState.modules?.entityGroups?.selectedEntityGroup?.id
          if (selectedGroupId) items = getters['getGroupObjects'](selectedGroupId)
        }
        return _.sortBy(items, (el) => $nuxt.$utils.getDisplayName(el?.id)?.toLowerCase()) // Sort the items by default
      },
      getAllItems(state) {
        if (Array.isArray(state.allItems)) {
          if (_.isNil(state.typeSelections)) {
            return state.allItems
          } else {
            return state.allItems.toSorted((a, b) => {
              const typeA = state.typeSelections.find((type) => type.value === a.type)
              const typeB = state.typeSelections.find((type) => type.value === b.type)

              const indexA = state.typeSelections.indexOf(typeA)
              const indexB = state.typeSelections.indexOf(typeB)

              // Compare the indices to determine the sorting order
              return indexA - indexB
            })
          }
        }
        return []
      },
      getTemplates: (state, getters) => (search) => {
        let items = getters['getAllItems'].filter((el) => !el.deleted || el.deleted === 'ARCHIVED')
        if (!search || typeof search !== 'string') return items.filter((el) => el.isTemplate)
        const formattedSearch = search.toLowerCase().trim()
        return items.filter((el) => el.isTemplate && el.name.toLowerCase().includes(formattedSearch))
      },
      getGroupObjects: (state, getters) => (groupId) => {
        return getters['getAllItems'].filter((el) => !el.deleted || el.deleted === 'ARCHIVED').filter((el) => el.entityGroupId === groupId || el.id === groupId)
      },
      getLabel: (state, getters, rootState, rootGetters) => (id) => {
        if (!id) return ''
        const cachedObj = typeof id === 'object' ? id : rootGetters[`application/globalSearch/getCacheObject`](id) // Check cache for ID
        const item = cachedObj ? cachedObj : Array.isArray(getters['getItems']) ? getters['getItems'].find((el) => el.id === id) : null // If not found in cache, try find it
        if (!item) return ''

        // Default to name or type
        if (item?.summary?.name || item?.name) return item?.summary?.name || item?.name
        else if (item.type) return $nuxt.$utils.capitaliseWords(item.type)
        return ''
      },
      getSubtitle: (state, getters, rootState, rootGetters) => (id, truncateOptions = { length: Number.MAX_VALUE }) => {
        if (!id) return ''
        const cachedObj = rootGetters[`application/globalSearch/getCacheObject`](id) // Check cache for ID
        const item = cachedObj ? cachedObj : Array.isArray(getters['getItems']) ? getters['getItems'].find((el) => el.id === id) : null // If not found in cache, try find it
        if (!item) return ''

        let result = ''
        const truncOpts = { length: Number.MAX_VALUE, omission: '...', separator: undefined }
        Object.assign(truncOpts, typeof truncateOptions == 'number' ? { length: truncateOptions } : truncateOptions)

        // Default to description
        if (item?.summary?.description) result = item?.summary?.description
        // Fallback to provider - product
        else if (item?.providerId || item?.productId) result = rootGetters['modules/providers/getLabel'](item.providerId) + ' - ' + rootGetters['modules/providers/getProductLabel'](item.productId)

        return _.truncate(result, truncOpts)
      },
      getFilterOptions(state, getters) {
        const defaultFields = ['type', 'status']

        return defaultFields.map((key) => ({
          text: $nuxt.$utils.capitaliseWords(key),
          value: key,
          options: _.uniq(getters.getItems.map((v) => _.get(v, key, '')))
            .filter((v) => !!v)
            .map((v) => ({ text: $nuxt.$utils.capitaliseWords(v), value: v, filter: (val) => val[key] === v })),
        }))
      },
      getTopBarButtons: (state, getters) => (currentRoute) => {
        return state.topBarButtons
      },
    }

    /**
     * @type { import('../../../types/store').Mutations<import('../../../types/store').CrudState> }
     */
    this.mutations = {
      setDebounceFetchIds(state, ids) {
        state.idsToFetch = _.uniq(state.idsToFetch.concat(ids))
      },
      updateObject(state, object) {
        const index = state.allItems.findIndex((el) => el.id === object?.id)
        if (index < 0) return
        state.allItems.splice(index, 1, object) // Replace the object
      },
      updateField(state, { id, field, value }) {
        const index = state.allItems.findIndex((el) => el.id === id)
        if (index < 0) return
        state.allItems[index][field] = value
      },
      addObject(state, object) {
        const index = state.allItems.findIndex((el) => el.id === object?.id)
        if (index >= 0) {
          state.allItems.splice(index, 1, object)
        } else {
          state.allItems.push(object)
        }
      },
      removeObjects(state, ids) {
        if (ids && !Array.isArray(ids)) ids = [ids]
        state.allItems = state.allItems.filter((el) => !ids.includes(el.id)) // Keep objects if the IDs aren't in the given IDs array
      },
      mergeItems(state, newItems) {
        const newIds = newItems.filter((el) => !_.isNil(el)).map((el) => el.id)
        let merged = state.allItems.filter((el) => !newIds.includes(el.id))
        merged = merged.concat(newItems)
        state.allItems = merged
      },
      setEdit(state, edit) {
        state.editMode = edit
      },
      restoreState(state, restoreState) {
        if (restoreState) {
          for (const [key, value] of Object.entries(restoreState)) {
            state[key] = value
          }
        }
      },
      resetPending(state) {
        state.pendingCreate = false
      },
    }

    /**
     * @type { import('../../../types/store').Actions<import('../../../types/store').CrudState> }
     */
    this.actions = {
      // Update an object with itself to trigger a refresh on tables or anything using the allItems array
      refreshAllItems({ state, commit }) {
        commit('updateObject', _.get(state.allItems, 0))
      },
      async fetchTemplates({ state, commit }) {
        commit('application/loading/setLoading', true, { root: true })
        const templates = await this.$axios.$get(`/api/v1/${endpoint}/templates`)
        commit('application/loading/setLoading', false, { root: true })
        commit('mergeItems', templates)
      },
      async isDeleted({ getters, dispatch }, id) {
        let obj = getters['getObject'](id)
        if (!obj) obj = await dispatch(`getById`, id)
        return !obj || obj.deleted ? true : false
      },
      async getAll({ state, commit, rootGetters, getters }, forceUpdate = false) {
        // Only fetch if data is stale
        if (state.staleData || forceUpdate) {
          commit('application/loading/setLoading', true, { root: true })
          commit('application/storeUtils/setItem', { localState: state, item: 'staleData', value: false }, { root: true })

          const result = await this.$axios.$get(`/api/v1/${endpoint}`).catch((err) => {
            // If fetch fails, set data to stale again
            commit('application/storeUtils/setItem', { localState: state, item: 'staleData', value: true }, { root: true })
            if (endpoint === 'entityGroups') throw err
          })
          commit('application/loading/setLoading', false, { root: true })

          commit('application/storeUtils/setItem', { localState: state, item: 'allItems', value: result }, { root: true })
        }
        return getters.getAllItems
      },
      async forceFetch({ getters, commit }, id) {
        const fetched = await this.$axios.$get(`/api/v1/${endpoint}/${id}?ignoreCache=true`)
        if (!fetched) return
        let found = getters['getObject'](id)
        if (found) commit('updateObject', fetched)
        else commit('addObject', fetched)
        return fetched
      },
      async getById({ commit, getters }, id) {
        let found = getters['getObject'](id) // Check if object has already been fetched
        if (!found) {
          try {
            // If not found in store, fetch
            found = await this.$axios.$get(`/api/v1/${endpoint}/${id}`)
            if (found) commit('addObject', found) // Add new object if not found
          } catch (e) {
            console.error('GetById Failed', e)
          }
        }
        return found ? _.cloneDeep(found) : null
      },
      async fetchIds({ commit }, ids) {
        if (!Array.isArray(ids) || ids.length == 0) return
        const res = await this.$axios.$post(`/api/v1/${endpoint}/fetchIds`, ids)
        commit('mergeItems', res) // Update store with fetched items
        return res
      },
      async fetchMissingIds({ dispatch, state }, ids) {
        if (!Array.isArray(ids)) return
        const missingIds = ids.filter((id) => state.allItems.findIndex((item) => item.id == id) == -1)
        await dispatch('fetchIds', missingIds)
      },
      fetchDebounce({ state, dispatch, commit }, ids) {
        commit('setDebounceFetchIds', ids)
        if (!state.debounceFetchIds) {
          const debounce = _.debounce(() => {
            dispatch('fetchIds', state.idsToFetch) // Fetch
            commit('application/storeUtils/setItem', { localState: state, item: 'idsToFetch', value: [] }, { root: true }) // Reset IDs to fetch
          }, 5000)
          commit('application/storeUtils/setItem', { localState: state, item: 'debounceFetchIds', value: debounce }, { root: true })
        }
        state.debounceFetchIds()
      },
      eventUpdate({ state, getters, commit, dispatch, rootState, rootGetters }, stompMessage) {
        // Do nothing if the event is missing a name or IDs
        if (!stompMessage || !stompMessage.eventName || !Array.isArray(stompMessage.ids)) return

        // Setup variables
        const data = _.get(stompMessage, 'data')
        const eventName = stompMessage.eventName.toLowerCase()
        const isAddEvent = eventName.includes('add') || eventName.includes('restore')
        const isUpdateEvent = eventName.includes('update') || eventName.includes('patch')
        const isDeleteEvent = eventName.includes('remove') || eventName.includes('archive')
        const isFileManagerEvent = stompMessage.topic == 'fileManager'
        const isSelectedId = stompMessage.ids.includes(this.$router.currentRoute.params.id)
        const isEditMode = state.editMode
        const isYourEvent = _.get(this.$auth, '$state.user.sub') === stompMessage.instigator
        const isDifferentTab = stompMessage.transactionId !== null && stompMessage.transactionId !== rootState.application.webStomp.transactionId
        // Variables as an object to pass into custom functions
        const props = { stompMessage, data, eventName, isAddEvent, isUpdateEvent, isDeleteEvent, isSelectedId, isEditMode, isYourEvent, isDifferentTab }

        // If a new group is added, fetch the automatically created single page data
        if (stompMessage.eventName === 'AddEntityGroupCommand') {
          setTimeout(() => {
            dispatch(`modules/entityGroups/fetchSinglePageData`, _.get(stompMessage, 'ids[0]'), { root: true })
          }, 2000)
        }

        if (isFileManagerEvent) {
          if (this.$utils.storeActionExists(`modules/${endpoint}`, 'onMessage')) {
            dispatch('onMessage', { stompMessage })
            return
          }
        }

        // Do nothing if it's your own event on the same page
        if (isYourEvent && !isDifferentTab) return

        // -----------------
        // --- ADD event ---
        // -----------------
        if (isAddEvent) {
          // Implement 'onStompAdd' in the child store for custom functionality or implement but leave blank to ignore the fetch
          if (this.$utils.storeActionExists(`modules/${endpoint}`, 'onStompAdd')) {
            dispatch('onStompAdd', props)
            return
          }
          // Add data from stomp message if it exists otherwise fetch it
          if (data) {
            commit('addObject', data)
          } else {
            setTimeout(() => {
              dispatch(isYourEvent ? 'fetchIds' : 'fetchDebounce', stompMessage.ids) // Don't debounce if it's your event, fetch it straight away
            }, 2000)
          }
        }

        // --------------------
        // --- UPDATE event ---
        // --------------------
        else if (isUpdateEvent) {
          // Implement 'onStompUpdate' in the child store for custom functionality or implement but leave blank to ignore the fetch
          if (this.$utils.storeActionExists(`modules/${endpoint}`, 'onStompUpdate')) {
            dispatch('onStompUpdate', props)
            return
          }
          if (isSelectedId && isEditMode && !isYourEvent) {
            // Show dialog > Yes = fetch the data, No = keep doing what you're doing
            commit(`application/webStomp/setStompMessage`, stompMessage, { root: true })
          } else {
            // Update data from stomp message if it exists otherwise fetch it
            if (data) {
              commit('updateObject', data)
            } else {
              setTimeout(() => {
                dispatch(isYourEvent ? 'fetchIds' : 'fetchDebounce', stompMessage.ids) // Don't debounce if it's your event, fetch it straight away
              }, 2000)
            }
          }
        }

        // --------------------
        // --- DELETE event ---
        // --------------------
        else if (isDeleteEvent) {
          // Implement 'onStompRemove' in the child store for custom functionality or implement but leave blank to ignore the fetch
          if (this.$utils.storeActionExists(`modules/${endpoint}`, 'onStompRemove')) {
            dispatch('onStompRemove', props)
            return
          }
          // Remove data
          commit('removeObjects', stompMessage.ids)
          if (isSelectedId) {
            // Show dialog saying the data is deleted
            commit(`application/webStomp/setStompMessage`, stompMessage, { root: true })
          }

          // An entity group was deleted
          if (stompMessage.eventName === 'RemoveEntityGroupCommand') {
            // Selected entity group was deleted, show message and deselect group
            if (stompMessage.ids.includes(rootGetters[`modules/entityGroups/getSelectedEntityGroup`]?.id)) {
              dispatch(`application/appData/setAllEdit`, false, { root: true })
              dispatch('modules/entityGroups/selectEntityGroup', { id: null }, { root: true }) // Deselect group
              commit(`application/webStomp/setStompMessage`, stompMessage, { root: true })
            }

            // Re-fetch all data after 5 seconds (wait for remove commands to be executed)
            setTimeout(() => {
              dispatch(`application/appData/fetchAllModuleData`, null, { root: true })
            }, 5000)
          }
        }
      },
      async patchStatus({ commit }, { id, status }) {
        if (!status) return
        commit('updateField', { id: id, field: 'status', value: status })
        await this.$axios.patch(`/api/v1/${endpoint}/status/${id}`, null, {
          params: {
            status: status,
          },
        })
      },
      async patch({ getters, dispatch }, { id, object, field }) {
        let existingObj = null
        const allItems = getters.getAllGroupItems
        // find existing object
        existingObj = _.find(allItems, { id })
        if (_.isNil(existingObj)) existingObj = _.find(getters.getAllItems, { id })
        if (!existingObj || existingObj.id !== id) return

        const payload = _.cloneDeep(existingObj)
        const fieldValue = _.get(object, field)
        _.set(payload, field, fieldValue)

        // This may need some work. It was essentially doing the same as above, except via merging. If any problems occur with persistency in single-edit, BetterCallSean
        // const editInsideArray = field.match(/[\.\[]\d+[\.\]]/g) // editing inside a dialog?
        // const fields = field.split(/[\.\[\]]/).filter(el => !!el),
        //       fieldsOriginal = _.clone(fields)
        // let fieldCursor = 0

        // const payload = _.mergeWith(_.cloneDeep(existingObj), object, (existingVal, newVal, key) => {
        //   // if the provided object goes deeper than the field, don't merge, instead overwrite with new value
        //   if (!fields.includes(key)) {
        //     return newVal
        //   } else fields.splice(0, 1)

        //   if (editInsideArray) {
        //     if (fieldCursor++ == fieldsOriginal.length) {
        //       // This is the target field being controlled by single-edit
        //       //  Merging arrays? We just want to choose the latest array
        //       if (Array.isArray(existingVal) && Array.isArray(newVal)) return newVal
        //     }
        //     return // default _.merge behavior when inside array
        //   }

        //   // Overwrite arrays with the new values instead of merging them
        //   if (!editInsideArray && Array.isArray(newVal)) return newVal
        // })

        return await dispatch('update', { payload })
      },
      async update({ commit, dispatch, getters }, { payload, callback, data }) {
        let rollbackRef = _.cloneDeep(Array.isArray(getters.getItems) ? getters.getItems.find((item) => item.id === payload.id) : getters.getItems)
        if (_.isNil(rollbackRef) && Array.isArray(getters.getAllItems)) {
          rollbackRef = _.cloneDeep(getters.getAllItems.find((item) => item.id === payload.id))
        }
        commit('application/loading/setLoading', true, { root: true })

        return await this.$axios
          .put(`/api/v1/${endpoint}/` + payload.id, payload)
          .then((res) => {
            commit('application/loading/setLoading', false, { root: true })
            const obj = res?.data?.object && !_.isEqual(res.data.object, payload) ? res.data.object : payload

            // Perform a link if there are any link fields
            if (this._actions[`modules/${endpoint}/updateLinks`]) dispatch(`updateLinks`, { payload: payload, old: rollbackRef })
            // Update the store
            commit('updateObject', obj)
            this.$bus.$emit('store-update', { store: endpoint, data: payload })

            if (callback) data !== null ? callback(res.status, data) : callback(res.status)
            return res
          })
          .catch((err) => {
            commit('application/loading/setLoading', false, { root: true })
            if (callback) callback(null)
            console.warn(err)
          })
      },
      async patchObject({ commit }, obj) {
        return await this.$axios.patch(`/api/v1/${endpoint}/patch`, obj).then((res) => {
          if (res?.status == 200) commit('updateObject', res?.data)
          return res
        })
      },
      async delete({ state, dispatch, commit, getters, rootState }, ids) {
        if (!Array.isArray(ids)) ids = [ids]
        if (ids.length === 0) return

        // Remove objects from state
        try {
          // Remove links
          if (this._actions[`modules/${endpoint}/removeLinks`]) dispatch(`removeLinks`, _.cloneDeep(ids))
          commit('removeObjects', ids)
          return await this.$axios.delete(`/api/v1/${endpoint}?ids=${ids.join(',')}`)
        } catch (e) {
          // Delete failed add it back in?
          // commit('addObject', newObject)
          return e
        }
      },
      async createNew({ state, commit, dispatch }, type) {
        // Create with type if type selections exist
        let newObj = (await this.$axios.get(`/api/v1/${endpoint}/new${_.get(state, 'typeSelections', []).length > 1 ? `/${type[0].value}` : ''}`)).data
        if (newObj) {
          if (type[1]) newObj.subType = type[1] != undefined ? type[1].value : null
          newObj.id = this.$uuid()
          commit('application/storeUtils/setItem', { localState: state, item: 'newEdit', value: newObj.id }, { root: true })
          dispatch('select', newObj.id)
          return newObj.id
        }
      },
      async saveNew({ state, commit, dispatch, rootState }, newObj) {
        return new Promise((resolve, reject) => {
          commit('application/storeUtils/setItem', { localState: state, item: 'newEdit', value: null }, { root: true })
          commit('application/storeUtils/setItem', { localState: state, item: 'pendingCreate', value: true }, { root: true })
          //Just checking we have the group on the obj
          //IF the obj getting saved has the group id. Set it
          if (_.has(newObj, 'entityGroupId') && !_.get(newObj, 'entityGroupId')) {
            newObj.entityGroupId = _.get(rootState.modules.entityGroups.selectedEntityGroup, 'id', null)
          }
          this.$axios
            .post(`/api/v1/${endpoint}`, newObj)
            .then((res) => {
              commit('application/storeUtils/setItem', { localState: state, item: 'pendingCreate', value: res.status }, { root: true })
              setTimeout(() => {
                commit('resetPending')
              }, 2000)

              // Handle object response
              const isObjectResponse = typeof res.data === 'object'
              const newId = isObjectResponse ? res.data?.id : res.data
              const newObject = isObjectResponse ? res.data : { ...newObj, id: newId, entityGroupId: rootState?.modules?.entityGroups?.selectedEntityGroup?.id }

              // Add object to this store
              commit('addObject', newObject)
              // this.$bus.$emit('saveNew:success', { endpoint, object: newObject, oldId: oldId })

              resolve(newId)
            })
            .catch((err) => {
              commit('application/storeUtils/setItem', { localState: state, item: 'pendingCreate', value: false }, { root: true })
              reject(err)
            })
        })
      },
      async restore({ commit }, payload) {
        if (!Array.isArray(payload)) return
        try {
          await this.$axios.$patch(`/api/v1/${endpoint}/restore`,payload.map(({ id }) => id))
          payload.forEach((obj) => {commit('addObject', obj)})
        } catch (e) {
          commit(
            'removeObjects',
            payload.map(({ id }) => id)
          )
          commit('application/snack/set', { type: 'error', message: `Failed to restore data.` }, { root: true })
        }
      },
      archive({ state, dispatch, commit, getters, rootState }, payload) {
        let rollbackRef = _.cloneDeep(Array.isArray(getters.getItems) ? getters.getItems.find((item) => item.id === payload.id) : getters.getItems)
        let archived = _.cloneDeep(payload)

        let ids = []

        archived.forEach((v) => {
          ids.push(v.id)
        })

        // TODO archive logic keeping state.allItems consistent
        commit('application/storeUtils/select', { localState: state, data: null }, { root: true })

        this.$axios.post(`/api/v1/${endpoint}/archives`, ids).catch((err) => {
          console.warn(err)
          this.$bus.$emit('store-update', rollbackRef)
        })

        commit('application/snack/set', { type: 'info', message: 'Data archived.', timeout: rootState.application.snack.infoTime, data: archived, module: state.endpoint, action: 'restore' }, { root: true })
      },
    }
  }
}
