import { conformToMask } from 'text-mask-core'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { AsYouType, getExampleNumber } from 'libphonenumber-js'
import examples from 'libphonenumber-js/mobile/examples'
import sanitizeHtml from 'sanitize-html'
import _ from 'lodash'

let localContext
export const utils = {
  // Strings to check for capitalisation on
  toCapitalise: ['SMSF', 'SAF', 'TTR', 'DVA', 'TPD', 'HECS HELP', 'HECS/HELP', 'FEE-HELP', 'SA-HELP', 'OS-HELP', 'TFN', 'FBT'],
  lowercase: ['And', 'Of', 'Or', 'To', 'At'],

  /**
   * Concatenates multiple strings or arrays of strings with a given separator and removes trailing separators.
   * @param {string} separator - The separator to use between strings.
   * @param {...string | string[]} strings - Any number of strings or arrays of strings to concatenate.
   * @returns {string} A concatenated string with the separator in between and no trailing separator.
   */
  concatStrings: (separator, ...strings) => {
    let concat = ''
    strings.forEach((string) => {
      if (!Array.isArray(string)) string = [string]
      string.forEach((el) => {
        if (el && typeof el === 'string' && el.trim() !== '') {
          concat += el + separator
        }
      })
    })
    // Remove separator from end of string
    if (concat.length > separator.length) {
      concat = concat.slice(0, -separator.length)
    }
    return concat
  },

  /**
   * Concatenates address fields into a single string, separated by commas.
   * @param {object} address - Object containing address details (addressOne, addressTwo, suburb, state, postCode).
   * @returns {string} A concatenated string with address details.
   */
  addressConcat: (address) => {
    return utils.concatStrings(', ', address.addressOne, address.addressTwo, address.suburb, address.state, address.postCode)
  },

  /**
   * Capitalizes each word in a string or array of strings. Converts certain words to lowercase and others to uppercase.
   * @param {string|string[]} words - A string or array of strings to be capitalized.
   * @param {string[]} [uppercase=[]] - An array of strings that should be converted to uppercase.
   * @param {boolean} [ignoreLowercase=false] - Whether to skip converting specific words to lowercase.
   * @returns {string|string[]} The capitalized string or array of strings.
   */
  capitaliseWords: (words, uppercase = [], ignoreLowercase = false) => {
    if (!words) return
    let isArray = Array.isArray(words)
    let capitalised = []
    if (_.isNil(words) || (!isArray && _.isObject(words))) return null
    if (isArray) {
      words.forEach((el) => {
        // if the word is like WILL & DANI Keep the &
        // else remove all symbols so "TYPE_A" becomes Type A
        if (el.includes('&') || el.includes('/')) {
          capitalised.push(el.replace(/\w+/g, _.capitalize))
        } else {
          capitalised.push(_.startCase(_.camelCase(el)))
        }
      })
    } else {
      if (words.includes('&') || words.includes('/')) {
        capitalised.push(words.replace(/\w+/g, _.capitalize))
      } else {
        capitalised.push(_.startCase(_.camelCase(words)))
      }
    }

    let toUpper = Array.isArray(uppercase) ? uppercase.concat(utils.toCapitalise) : [uppercase].concat(utils.toCapitalise)
    for (let i = 0; i < capitalised.length; i++) {
      // Convert certain words to lowercase
      if (!ignoreLowercase) {
        utils.lowercase.forEach((lower) => {
          const rgx1 = new RegExp(`[?!^ ]${lower}[ ]`)
          // above: only replace 'or'/'and' etc if they are surrounded by a space and aren't at the start of the string
          capitalised[i] = capitalised[i].replace(rgx1, ' ' + lower.toLowerCase() + ' ')
        })
      }

      if (toUpper.length > 0) {
        // Uppercase certain words if required
        let foundIndex = toUpper.findIndex((el) => capitalised[i].toLowerCase().includes(el.toLowerCase()))
        if (foundIndex >= 0) {
          const words = capitalised[i].split(' ')
          // Capitalize specific words
          const capitalizedWords = words.map((word) => {
            const uppercaseWord = word.toUpperCase()
            if (utils.toCapitalise.includes(uppercaseWord)) {
              return uppercaseWord
            }
            return word
          })
          capitalised[i] = capitalizedWords.join(' ')
        }
      }
    }
    return isArray ? capitalised : capitalised[0]
  },

  /**
   * Validates a date and converts it to ISO format if valid.
   * @param {string} date - Date string in various formats.
   * @param {boolean} [isMonth=false] - Whether to validate the date as a month/year format.
   * @returns {boolean} True if the date is valid, otherwise false.
   */
  validDate(date, isMonth = false) {
    if (!date || typeof date !== 'string') return false
    if (isMonth) {
      const [month, year] = date.split('/').length === 2 ? date.split('/') : date.split('/').splice(1)
      if (month !== undefined && year !== undefined) {
        date = `${year}-${month.padStart(2, '0')}-01`
      }
    } else {
      const [day, month, year] = date.split('/')
      if (day !== undefined && month !== undefined && year !== undefined) {
        date = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      }
    }

    date = utils.dateToIso(date, isMonth)
    if (utils.isIso(date) && localContext.$moment(date).isValid()) {
      return true
    }
    return false
  },

  /**
   * Checks if a string is in ISO date format.
   * @param {string} date - The date string to check.
   * @returns {boolean} True if the string is in ISO format, otherwise false.
   */
  isIso(date) {
    if (/^\d{4}-\d{1,2}-\d{1,2}$/.test(date) || /^\d{4}-\d{1,2}$/.test(date)) {
      return true
    }
    return false
  },

  /**
   * Converts a date string to ISO format.
   * @param {string} date - Date string in various formats.
   * @param {boolean} [month=false] - Whether to format the date as month/year.
   * @returns {string} ISO formatted date string.
   */
  dateToIso: (date, month = false) => {
    if (!date || date === undefined) {
      return ''
    }
    if (/^\d{4}$/.test(date)) return date + '-01-01'
    if (date instanceof Date) date = date.toISOString()
    if (utils.isIso(date)) {
      if (/^\d{4}-\d{1,2}$/.test(date) && !month) {
        // In month format but month no month parameter, append day to the ISO date
        return date + '-01'
      }
      return date
    } else if (!month && /^\d{4}-\d{1,2}-\d{1,2}/.test(date)) {
      // Is ISO format but has extra characters
      return date.replace(/(\d{4}-\d{1,2}-\d{1,2}).*/, '$1')
    } else if (month && /^\d{4}-\d{1,2}/.test(date)) {
      return date.replace(/(\d{4}-\d{1,2}).*/, '$1')
    }

    // Check if the date is in short or long format, convert either to ISO format
    try {
      if (!month && /^\d{1,2}\/\d{1,2}\/\d{4}$/.test(date)) {
        // Passing full date
        const [day, month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      } else if (localContext.$moment(date, 'Do MMM[,] YYYY').format('Do MMM[,] YYYY') === date) {
        return localContext.$moment(date, 'Do MMM[,] YYYY').format('YYYY-MM-DD')
      } else if (month && (/^\d{1,2}\/\d{4}$/.test(date) || /^\d{1,2}\/\d{1,2}\/\d{4}$/.test(date))) {
        // Passing month/year
        const [month, year] = date.split('/').length === 2 ? date.split('/') : date.split('/').splice(1)
        return `${year}-${month.padStart(2, '0')}`
      } else if (localContext.$moment(date, 'MMM[,] YYYY').format('MMM[,] YYYY') === date) {
        return localContext.$moment(date, 'MMM[,] YYYY').format('YYYY-MM')
      }
    } catch (e) {
      console.log('Error passing date: ' + e)
    }
    return ''
  },

  /**
   * Formats a date string to a specific output format.
   * @param {string} date - Date string in various formats.
   * @param {boolean} [toShortFormat=false] - Whether to format as a short date (DD/MM/YYYY).
   * @returns {string} Formatted date string.
   */
  formatDate: (date, toShortFormat = false) => {
    if (!date || date === undefined) {
      return ''
    }
    date = utils.dateToIso(date)
    if (date) {
      let formatted = toShortFormat ? localContext.$moment(date).format('DD[/]MM[/]YYYY') : localContext.$moment(date).format('Do MMM[,] YYYY')
      return formatted !== 'Invalid date' ? formatted : ''
    }
    return ''
  },

  /**
   * Formats a month string to a specific output format.
   * @param {string} date - Date string in various formats.
   * @param {boolean} [toShortFormat=false] - Whether to format as a short date (MM/YYYY).
   * @returns {string} Formatted month string.
   */
  formatMonth: (date, toShortFormat = false) => {
    if (!date || date === undefined) {
      return ''
    }
    date = utils.dateToIso(date, true)
    if (date) {
      let formatted = toShortFormat ? localContext.$moment(date).format('MM[/]YYYY') : localContext.$moment(date).format('MMM[,] YYYY')
      return formatted !== 'Invalid date' ? formatted : ''
    }
    return ''
  },

  /**
   * Formats a date range (start and end) and returns a string with the formatted date range.
   * @param {object} date - Object containing start and end date.
   * @param {boolean} [month=false] - Whether to format the range in month/year format.
   * @returns {string} Formatted date range.
   */
  formatDateRange: (date, month = false) => {
    if (!date || !date.start) return ''
    const formatter = month ? utils.formatMonth : utils.formatDate
    // Check if one off
    if (date.oneOff) return formatter(date.start) + ' (One-off)'
    // Check if no end
    if (!date.end) return formatter(date.start) + ' - Ongoing'
    // Check if end actually comes after start
    return formatter(date.start) + ' - ' + formatter(date.end)
  },

  /**
   * Formats a phone number using international formatting.
   * @param {string} number - Phone number to format.
   * @param {string} [countryCode='AU'] - Country code for the phone number.
   * @returns {string} Formatted phone number.
   */
  formatPhone: (number, countryCode = 'AU') => {
    if (!number && number !== '0') return ''
    number = String(number)
    const inputVal = number.replace(/\s/g, '')
    const asYouType = new AsYouType(countryCode)
    asYouType.input(inputVal)
    return (asYouType.getNumber() && asYouType.getNumber().formatInternational()) || ''
  },

  /**
   * Validates a phone number and returns the validity and an example number if invalid.
   * @param {string} number - Phone number to validate.
   * @returns {object} Object with `valid` (boolean) and `example` (string) keys.
   */
  isPhoneValid(number) {
    if (!number) return { valid: true, example: '' }
    const asYouType = new AsYouType()
    asYouType.input(number)
    if (asYouType.isValid()) return { valid: true, example: '' }
    const phoneNumber = getExampleNumber(asYouType.getNumber()?.country, examples)
    return { valid: false, example: phoneNumber && phoneNumber.formatInternational() }
  },

  /**
   * Concatenates parts of an address into a string, with optional hiding of the country.
   * @param {object} address - Object containing address details.
   * @param {boolean} [hideCountry=false] - Whether to hide the country in the output string.
   * @returns {string} Concatenated address string.
   */
  formatPlace: (address, hideCountry = false) => {
    if (!address) return ''
    if (address.country === 'Australia') hideCountry = true
    return utils.concatStrings(', ', address.addressOne, address.addressTwo, (address.suburb ? address.suburb : '') + ' ' + (address.state ? address.state : ''), address.postCode, hideCountry ? '' : address.country)
  },
  /**
   * Formats various types of numbers (e.g., digit, number, money, percent) according to a specified format mask.
   * @param {string|number} number - The number to format.
   * @param {string} [numType='digit'] - The type of number formatting to apply (e.g., 'digit', 'money', 'percent').
   * @param {boolean} [noCommas=false] - Whether to omit commas in the formatted result.
   * @param {boolean} [negative=false] - Whether the number is negative.
   * @returns {string} The formatted number.
   */
  formatNumber(number, numType = 'digit', noCommas = false, negative = false) {
    if (!number && number !== 0) return number

    number = this.stripNumber(number, negative)

    let mask = null
    switch (numType) {
      case 'digit':
        mask = {
          prefix: '',
          suffix: '',
          includeThousandsSeparator: !noCommas,
          allowNegative: true,
          allowDecimal: false,
        }
        break
      case 'number':
        mask = {
          prefix: '',
          suffix: '',
          includeThousandsSeparator: !noCommas,
          allowNegative: true,
          allowDecimal: true,
        }
        break
      case 'money':
      case 'money-prefix':
      case 'percent':
      case 'percent-suffix':
        mask = {
          // Formatting in tables
          prefix: numType === 'money-prefix' ? '$' : '',
          suffix: numType === 'percent-suffix' ? '%' : '',
          includeThousandsSeparator: !noCommas,
          allowNegative: true,
          allowDecimal: true,
          decimalLimit: numType === 'money' ? 2 : 4,
        }
        break
      case 'bsb':
        mask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]
        break
      case 'abn':
        mask = [/\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/]
        break
      case 'tfn':
        mask = [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/]
        break
      default:
        mask = ''
        break
    }

    let _mask = []
    if (Array.isArray(mask)) {
      _mask = mask
    } else if (typeof mask === 'object') {
      _mask = createNumberMask(mask)(number)
      if (Array.isArray(_mask)) _mask = _mask.filter((v) => v !== '[]')
    }

    const result = conformToMask(number, _mask, { guide: false })
    if (result.conformedValue) return result.conformedValue
    else return number
  },

  /**
   * Strips special characters (e.g., commas, underscores, dollar signs) from a number.
   * @param {string|number} number - The number to strip special characters from.
   * @param {boolean} [negative=false] - Whether to retain the negative sign.
   * @returns {string} The sanitized number string.
   */
  stripNumber(number, negative = false) {
    if (number === null || number === undefined) return String('')
    if (typeof number === 'number') {
      if (number > Number.MAX_SAFE_INTEGER) {
        number = number.toLocaleString()
      }
    }
    let num = String(number)
    if (negative && num.charAt(0) !== '-') num = '-' + num // Make the number negative if needed

    return num
      .replace(/[,_%$]/g, '') // Remove commas, underscores, dollar, and percent signs
      .replace(/(?<!^)-/g, '') // Remove dashes unless at the start (to allow negatives)
      .trim()
  },

  /**
   * Adds commas to a number string for thousands separators.
   * @param {string|number} num - The number to add commas to.
   * @param {boolean} [decimal=true] - Whether the number contains decimals.
   * @returns {string} The formatted number string with commas.
   */
  addCommas: (num, decimal = true) => {
    if (num || num == 0) {
      let numString = num.toString()
      let negative = false

      if (numString.trim().charAt(0) === '-') negative = true

      if (decimal) {
        numString = numString.replace(/[^0-9.]/g, '').replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
      } else {
        numString = numString.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }

      if (negative) numString = '-' + numString

      return numString
    }
    return ''
  },

  /**
   * Removes commas from a number string.
   * @param {string|number} num - The number string to remove commas from.
   * @param {boolean} [decimal=true] - Whether the number contains decimals.
   * @returns {string} The number string without commas.
   */
  removeCommas: (num, decimal = true) => {
    if (num) {
      let numString = num.toString()
      let negative = false

      if (numString.includes('-')) {
        negative = true
        numString = numString.substring(numString.indexOf('-'), numString.length)
      }

      if (decimal) {
        numString = numString.replace(/[^0-9.]/g, '').replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '')
      } else {
        numString = numString.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '')
      }

      if (negative) numString = '-' + numString
      return numString
    }
    return 0
  },

  /**
   * Displays a monetary value with formatting.
   * @param {string|number} num - The number to format as money.
   * @param {number} [decimals=2] - Number of decimal places.
   * @param {boolean|Array} [avoidZeroDollarAmount=false] - Avoid displaying zero as '$0'.
   * @returns {string} The formatted monetary value.
   */
  moneyDisplay: (num, decimals = 2, avoidZeroDollarAmount = false) => {
    if (num === null || num === undefined) return '--'
    num = utils.removeCommas(num)

    if ((typeof avoidZeroDollarAmount === 'boolean' && avoidZeroDollarAmount === true) || (Array.isArray(avoidZeroDollarAmount) && avoidZeroDollarAmount.length === 0)) {
      if (num === 0 || num === '0' || num === '$0') {
        return '--'
      }
    }

    let negative = false
    if (decimals == 0) num = _.round(Number(num), 0)
    else num = parseFloat(num).toFixed(decimals)
    num = String(num)
    if (num.includes('-')) {
      num = num.substring(num.indexOf('-') + 1, num.length)
      negative = true
    }
    if (num === '0') return '$0'
    return utils.formatNumber(num, 'money-prefix', false, negative)
  },

  /**
   * Calculates the percentage of a value.
   * @param {number} percentage - The percentage to apply.
   * @param {number} amount - The base amount.
   * @param {number} [decimals=2] - The number of decimal places to round to.
   * @returns {number} The calculated percentage of the amount.
   */
  percentOf(percentage, amount, decimals = 2) {
    if (!amount || !percentage) return 0
    return _.round((Number(percentage) / 100) * Number(amount), decimals)
  },

  /**
   * Formats a percentage value for display.
   * @param {number} num - The percentage value.
   * @param {number} [decimals=2] - The number of decimal places.
   * @param {boolean} [trailingZeroes=false] - Whether to include trailing zeroes in the result.
   * @param {boolean} [valueOnly=false] - Whether to return the value only (without a '%' symbol).
   * @returns {string} The formatted percentage.
   */
  percentDisplay(num, decimals = 2, trailingZeroes = false, valueOnly = false) {
    let rounded = _.round(num, decimals)
    if (!rounded) return valueOnly ? '' : '--'

    if (trailingZeroes) {
      rounded = rounded.toString()
      // Regex to check if decimals are missing
      const regex = new RegExp(`^-?(\\d+(\\.\\d{1,${decimals}})?)?$`)
      const match = regex.exec(rounded)
      if (match) {
        let missingDecimalPlaces = 0
        if (match[2]) {
          // Determine how many decimal places are missing
          const existingDecimalPlaces = match[2].length - 1
          missingDecimalPlaces = decimals - existingDecimalPlaces
          if (missingDecimalPlaces < 0) missingDecimalPlaces = 0
        } else {
          // Missing all decimals, add '.' as well
          missingDecimalPlaces = decimals
          rounded += '.'
        }

        if (missingDecimalPlaces > 0) {
          for (let i = 0; i < missingDecimalPlaces; i++) {
            rounded += '0'
          }
        }
      }
    }
    return rounded + (valueOnly ? '' : '%')
  },

  /**
   * Calculates the percentage between two values.
   * @param {number} valueOne - The numerator.
   * @param {number} valueTwo - The denominator.
   * @returns {number} The percentage of valueOne relative to valueTwo.
   */
  getPercentage(valueOne, valueTwo) {
    if (!valueOne || !valueTwo) return 0
    return _.round((Number(valueOne) / Number(valueTwo)) * 100, 2)
  },

  /**
   * Adds brackets around a value based on a condition.
   * @param {string} value - The value to wrap in brackets.
   * @param {boolean} [condition=true] - Whether to wrap the value in brackets.
   * @returns {string} The value wrapped in brackets, or the original value.
   */
  addBrackets(value, condition = true) {
    if (condition && value && value !== '--') return `(${value})`
    return value
  },

  /**
   * Sums values in an array of objects.
   * @param {Array} arr - The array of objects.
   * @param {string} key - The key to sum in each object.
   * @param {string|null} [key2=null] - An optional second key to access nested values.
   * @returns {number|null} The sum of the values, or null if the array is empty.
   */
  sumArrayObjs: (arr, key, key2 = null) => {
    if (!Array.isArray(arr)) return null

    let invalidIndices = []
    arr.forEach((val, idx) => {
      let v = key2 === null ? val[key] : val[key][key2]
      if (v === '' || v === null || v === undefined) invalidIndices.push(idx)
    })
    if (invalidIndices.length === arr.length) return null

    return arr.reduce((a, b) => a + (Number(key2 ? b[key][key2] : b[key]) || 0), 0)
  },

  /**
   * Finds an object in an array based on a matching value.
   * @param {Array} arr - The array to search.
   * @param {*} value - The value to find.
   * @param {string} [itemValue='value'] - The key to compare the value to.
   * @returns {Object|boolean} The matched object or false if not found.
   */
  objFromValue(arr, value, itemValue = null) {
    let key = itemValue ? itemValue : 'value'
    if ((arr && Array.isArray(arr) && arr.length > 0 && value) || value == 0) {
      return arr.find((item) => item[key] === value || (value.value && item[key] === value.value))
    }
    return false
  },

  /**
   * Retrieves the display name of an object by matching a value in an array.
   * @param {Array} arr - The array of objects.
   * @param {*} value - The value to search for.
   * @param {string|null} [itemValue=null] - Optional item key to match the value.
   * @returns {string} The name associated with the value.
   */
  nameFromValue: (arr, value, itemValue = null) => {
    let obj = utils.objFromValue(arr, value, itemValue)
    if (obj) return obj.name
  },

  /**
   * Calculates the total value of a portfolio by summing up the unit price of items.
   * @param {Object} portfolio - The portfolio object containing items and their unit prices.
   * @returns {number} The total value of the portfolio.
   */
  totalPortfolioValue: (portfolio) => {
    let total = 0
    for (const [key, value] of Object.entries(portfolio)) {
      if (value.data && value.data.length > 0) {
        value.data.forEach((item) => {
          if (item.unitPrice > 0 && item.costBase.length > 0) {
            total += Number(item.unitPrice) * Number(utils.sumArrayObjs(item.costBase, 'units'))
          } else if (item.valueInput) {
            total += Number(item.valueInput)
          }
        })
      }
    }
    return total
  },

  /**
   * Checks if a store action exists within a Vuex store.
   * @param {string} store - The store module name.
   * @param {string} action - The action name to check.
   * @returns {boolean} True if the action exists, otherwise false.
   */
  storeActionExists: (store, action) => {
    const methodName = store + '/' + action
    const actionExists = Object.keys(localContext.store._actions).findIndex((key) => key === methodName) !== -1
    return actionExists
  },

  /**
   * Checks if a value is a valid UUID or a 24-character string.
   * @param {string} val - The value to validate.
   * @returns {boolean} True if the value is a valid UUID or 24-char string, otherwise false.
   */
  isUUID: (val) => {
    if (!val || typeof val !== 'string') return false
    return val.match(/[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/i) !== null || /^[a-z0-9]{24}$/.test(val) || (typeof val === 'string' && val.substring(0, 5) === 'auth0')
  },

  /**
   * Trims a string to a maximum length and adds an omission (e.g., '...') if the string is longer than the maximum.
   * @param {string} str - The string to trim.
   * @param {number} [maxLength=16] - The maximum length of the string.
   * @param {string} [omission='...'] - The string to append if the original string exceeds the max length.
   * @returns {string} The trimmed string with omission if necessary.
   */
  trimString(str, maxLength = 16, omission = '...') {
    if (typeof str !== 'string') return str
    if (typeof maxLength !== 'number') {
      maxLength = parseInt(maxLength)
      if (typeof maxLength !== 'number' || Number.isNaN(maxLength)) return str
    }
    return _.truncate(str, { length: maxLength, omission })
  },

  /**
   * Shortcut to call `trimString` with default parameters.
   * @param {string} str - The string to truncate.
   * @param {number} [maxLength=16] - Maximum length of the string.
   * @param {string} [omission='...'] - The string to append if truncation occurs.
   * @returns {string} The truncated string.
   */
  truncate(str, maxLength = 16, omission = '...') {
    return this.trimString(str, maxLength)
  },

  /**
   * Formats a date for server submission (e.g., converts DD/MM/YYYY to YYYY-MM-DD).
   * @param {string} date - The date string to format.
   * @returns {string|null} The formatted date string, or null if the input is invalid.
   */
  formatDateForSever(date) {
    let momentDate = localContext.$moment(date, 'DD/MM/yyyy')
    if (!momentDate.isValid()) momentDate = localContext.$moment(date)
    if (!momentDate.isValid()) return null
    return _.cloneDeep(momentDate).format('yyyy-MM-DD')
  },

  /**
   * Converts ownership details into a display string.
   * @param {object|string} ownership - Ownership object or string.
   * @param {boolean} [jointOverride=false] - Whether to override the joint ownership flag.
   * @returns {string} A formatted string describing the ownership.
   */
  getOwnershipString(ownership, jointOverride = false) {
    let owners = null
    if (ownership?.items) owners = ownership.items
    else if (typeof ownership === 'string') owners = [{ name: ownership }]
    if (!owners || !Array.isArray(owners)) return ''

    let ownerships = []
    owners.forEach((el) => {
      let name = utils.isUUID(el.name) ? utils.getDisplayName(el.name) : el.name
      let percentage = el.percentage ? `(${Number(el.percentage).toLocaleString(window.navigator.language, { maximumFractionDigits: 2 })}%)` : ''

      if (name) ownerships.push(`${name} ${percentage}`)
    })

    return ownerships.join(', ')
  },

  /**
   * Gets a subtitle for a given object, usually based on its ID.
   * @param {string|object} id - The object or its ID.
   * @returns {string} A subtitle for the object, or an empty string if unavailable.
   */
  getSubtitle(id) {
    let idRef = id
    if (typeof id === 'object') {
      localContext.store.commit('application/globalSearch/updateIdCache', [id])
      idRef = _.get(id, 'id') // Convert object to just ID
    }
    if (!idRef || typeof idRef !== 'string') return ''
    const module = utils.storeFromId(idRef)
    if (!module) return ''
    if (localContext.store.getters[`modules/${module}/getSubtitle`]) {
      // If a getSubtitle getter exists, use it
      return localContext.store.getters[`modules/${module}/getSubtitle`](idRef)
    }
    return ''
  },

  /**
   * Gets the display name of an object from its ID or object with an ID.
   * @param {string|object} id - The ID or object to get the display name for.
   * @param {string} [backupNameKey='name'] - Key for a backup name if the object lacks an ID prefix.
   * @returns {string} The display name, or 'Unnamed' if unavailable.
   */
  getDisplayName(id, backupNameKey = 'name') {
    const defaultName = 'Unnamed'
    const backupName = _.get(id, backupNameKey, defaultName)
    if (typeof id === 'object') {
      localContext.store.commit('application/globalSearch/updateIdCache', [id])
      id = _.get(id, 'id') // Convert object to just ID
    }
    if (!id || typeof id !== 'string') return defaultName
    if (!utils.isUUID(id) && !id.includes('requests-') && !id.includes('activities-')) return id // Is a string but not a UUID, might be the name already
    const module = utils.storeFromId(id).trim()
    if (!module) return backupName
    try {
      const name = localContext.store.getters[`modules/${module}/getLabel`](id)
      if (!name) {
        // Try fetch data if it hasn't been fetched so the name can then be displayed (e.g. for ownership labels)
        localContext.store.dispatch(`modules/${module}/getById`, id)
        return defaultName
      }
      return name
    } catch (ex) {
      console.error('Could not find getLabel getter for store ', module, ex)
      return defaultName
    }
  },

  /**
   * Gets display names for multiple object IDs.
   * @param {Array<string>} ids - An array of object IDs.
   * @returns {Array<string>} An array of display names.
   */
  getMultipleDisplayNames(ids) {
    let names = []
    if (!Array.isArray(ids)) return names
    ids.forEach((id) => {
      names.push(utils.getDisplayName(id))
    })
    return names
  },

  /**
   * Gets a value from an object, returning a default value if the result is null or undefined.
   * @param {object} object - The object to get the value from.
   * @param {string} path - The path to the value in the object.
   * @param {*} defaultValue - The default value to return if the value is null or undefined.
   * @returns {*} The value from the object, or the default value.
   */
  getWithDefault(object, path, defaultValue) {
    const result = _.get(object, path, null)
    return _.isNil(result) ? defaultValue : result
  },

  /**
   * Trims a string and returns a default value if the string is empty or null.
   * @param {string} string - The string to check.
   * @param {string} defaultTo - The default value to return if the string is empty.
   * @returns {string} The trimmed string or the default value.
   */
  stringDefault(string, defaultTo) {
    if (!string || string.trim() === '') return defaultTo
    return string
  },

  /**
   * Extracts the initials from a full name.
   * @param {string} fullname - The full name.
   * @returns {string} The initials from the name.
   */
  initials(fullname) {
    let initials = ''
    let split = fullname.split(' ')
    for (let i = 0; i <= 1; i++) {
      if (split[i] && typeof split[i] === 'string') initials += split[i].charAt(0).toUpperCase()
    }
    return initials
  },

  /**
   * Parses route parameters from the current route or a given route.
   * @param {string|null} [route=null] - The route to parse, defaults to current route.
   * @param {string} [splitter='/'] - The delimiter used to split the route.
   * @returns {Array<string>} An array of route parameters.
   */
  getRouteParams(route = null, splitter = '/') {
    let toSplit = route ? route : localContext.route.path
    toSplit = toSplit.charAt(0) === splitter ? toSplit.slice(1) : toSplit
    return _.split(toSplit, splitter)
  },

  /**
   * Appends new options to an existing list, ensuring unique values.
   * @param {Array} list - The original list of options.
   * @param {Array|string} newActivities - The new activities to append.
   * @returns {Array} The updated list of options.
   */
  appendOptions(list, newActivities) {
    if (_.isNil(newActivities)) return list
    if (!Array.isArray(newActivities)) newActivities = [newActivities]
    // Convert new activities to start-case camel case
    const formattedActivities = newActivities.map((activity) => _.startCase(_.camelCase(activity)))
    // Append the formatted activities to the original list
    const updatedList = list.concat(formattedActivities.map((name) => ({ name, value: _.upperCase(name) })))
    return _.uniqBy(updatedList, 'value')
  },

  /**
   * Checks if an element is overflowing its container.
   * @param {HTMLElement} el - The element to check.
   * @returns {boolean} True if the content is overflowing, otherwise false.
   */
  isOverflowing(el) {
    let component = el
    if (component) {
      if (_.get(component, '[0]')) component = component[0]
      // If ref is for NuxtLink, the child element is the component
      if (component?._name === '<NuxtLink>') {
        component = _.get(component, '$el.children[0]')
      }
      if (component?.clientWidth && component?.scrollWidth) {
        return component.clientWidth < component.scrollWidth
      }
    }
    return false
  },

  /**
   * Gets the computed CSS value of a color from a CSS variable.
   * @param {string} colorKey - The CSS variable key (e.g., '--v-primary-lighten3').
   * @returns {string} The computed color value as a hex string.
   */
  getComputedColor(colorKey) {
    return getComputedStyle(document.documentElement).getPropertyValue(colorKey).trim()
  },

  /**
   * Generates a unique copy name based on an existing name.
   * @param {string} name - The original name.
   * @param {Array<string>} existingNames - An array of existing names to avoid duplicates.
   * @returns {string} A unique copy name (e.g., "My Name (1)").
   */
  generateCopyName(name, existingNames) {
    if (!Array.isArray(existingNames)) return name + ' (1)'

    let newName = typeof name === 'string' ? name.trim() : name
    let match = newName.match(/\((\d)\)$/)
    let num = 0

    if (match) {
      // Use existing number as the starting number
      num = parseInt(match[1])
    } else {
      // 0 gets bumped up to 1
      newName += ' (0)'
    }

    do {
      num++
      if (newName.includes(`(${num - 1})`)) {
        // Increment the number
        newName = newName.replace(`(${num - 1})`, `(${num})`)
      } else {
        // Default to appending (1) if the number could not be replaced for some reason
        newName += ' (1)'
      }
    } while (existingNames.includes(newName))

    return newName
  },

  /**
   * Extracts the store name from an ID by assuming the prefix before a dash.
   * @param {string} id - The ID to extract the store name from.
   * @returns {string} The store name extracted from the ID.
   */
  storeFromId(id) {
    if (!id || typeof id !== 'string') return ''
    let subStr = id.substring(0, id.indexOf('-'))
    if (!/^[a-zA-Z]+$/.test(subStr)) {
      return ''
    }
    return subStr || ''
  },

  /**
   * Retrieves an object from a store based on its ID.
   * @param {string} id - The ID of the object.
   * @returns {Object|null} The object, or null if not found.
   */
  objectFromId(id) {
    const store = utils.storeFromId(id)
    if (store) return localContext.store.getters[`modules/${store}/getObject`](id)
    return null
  },

  /**
   * Checks if an object has been deleted based on its ID.
   * @param {string|object} id - The ID or object to check.
   * @returns {boolean} True if the object is deleted, otherwise false.
   */
  isObjectDeleted(id) {
    if (!typeof id === 'string') id = id?.id
    const obj = utils.objectFromId(id)
    return _.isNil(obj) || !_.isNil(obj.deleted)
  },

  /**
   * Maps an enum from the backend to a list of name-value pairs.
   * @param {string} enumName - The name of the enum class.
   * @param {object} [nameMap=null] - Optional name mapping for custom names.
   * @param {boolean} [valueAsName=false] - Whether to use the name as the value in the mapping.
   * @returns {Array} An array of objects with name and value keys.
   */
  mapEnums(enumName, nameMap = null, valueAsName = false) {
    return localContext.store.getters['application/appData/enum'](enumName).map((el) => {
      const name = _.get(nameMap, el, utils.capitaliseWords(el))
      return { name: name, value: valueAsName ? name : el }
    })
  },

  /**
   * Checks if an object is null or undefined, returning a default value if it is.
   * @param {*} obj - The object to check.
   * @param {*} defaultVal - The default value to return if the object is null or undefined.
   * @returns {*} The object or the default value.
   */
  isNil(obj, defaultVal) {
    return !_.isNil(obj) ? (obj !== 'null' ? obj : defaultVal) : defaultVal
  },

  /**
   * Triggers a download for a Blob as a file.
   * @param {Blob} blob - The blob data to download.
   * @param {string} fileName - The name of the file to download.
   */
  downloadBlob(blob, fileName) {
    try {
      var a = document.createElement('a')
      document.body.appendChild(a)
      a.style = 'display: none'
      let url = window.URL.createObjectURL(blob)
      a.href = url
      a.download = fileName
      a.click()
      window.URL.revokeObjectURL(url)
    } catch (e) {
      console.error('Failed to download blob:', e)
    }
  },

  /**
   * Converts a base64 string into a File object.
   * @param {string} data - The base64 data string.
   * @param {string} fileName - The desired file name for the file.
   * @returns {File|null} The File object, or null if conversion fails.
   */
  base64toFile(data, fileName) {
    if (!data) return null
    if (typeof data !== 'string') return null
    var arr = data.split(',')
    let match = arr[0].match(/:(.*?);/)
    let mime = match[1]
    let bstr = atob(arr[arr.length - 1])
    let n = bstr.length
    let u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], fileName, { type: mime })
  },

  /**
   * Sanitizes an HTML input string by allowing only certain tags and attributes.
   * @param {string} input - The HTML input string.
   * @returns {string} The sanitized HTML string.
   */
  sanitize(input) {
    return sanitizeHtml(input, {
      allowedTags: ['b', 'u', 'i', 'em', 'strong', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span', 'div', 'br', 'ul', 'ol', 'li', 'sub', 'code', 'pre', 'sup', 'blockquote', 'img'],
      parseStyleAttributes: true,
      allowedAttributes: {
        '*': ['style', 'class', 'data-*'],
        img: ['src', 'alt', 'title', 'width', 'height'],
      },
      allowedSchemes: ['data', 'https'],
    })
  },

  /**
   * Masks text based on the provided mask type (e.g., enums, users, entities).
   * @param {string} text - The text to mask.
   * @param {string} mask - The mask type to apply.
   * @returns {string} The masked text.
   */
  maskText(text, mask) {
    switch (mask) {
      case 'enums':
        return _.startCase(text.toLowerCase())
      case 'users':
      case 'assignedUsers':
        if (!String(text).startsWith('auth0|')) return text // 'no assignees', or 'myself'
        return $nuxt.$store.getters['auxiliary/user/getUsernameForId'](text)
      case 'entities':
      case 'assignedEntities':
        const entityName = this.getDisplayName(text)
        return entityName != '' ? entityName : 'Not Found'
      case 'entityGroupId':
        return $nuxt.$store.getters['modules/entityGroups/getGroupName'](text) || 'None Set'
      case 'team':
        const team = $nuxt.$store.getters[`modules/configurationHub/getTeams`].find((team) => team.id === text)
        return team ? team.name : 'None Set'
      case 'taskTags':
      case 'tags':
        let tag = $nuxt.$store.getters[`modules/configurationHub/getTag`](text)
        return tag?.name ?? null
      case 'configurationHub':
        let configItem = $nuxt.$store.getters[`modules/configurationHub/idSearch`](text)
        return configItem?.name ?? null
      case 'dueDate':
        return this.capitaliseWords(this.calcDueDateTitle(text))
      case 'status':
      case 'type':
        return this.capitaliseWords(text)
      default:
        return !!text ? text : 'None Set'
    }
  },

  /**
   * Calculates a title based on a due date.
   * @param {string} date - The due date string.
   * @returns {string} The calculated due date title.
   */
  calcDueDateTitle(date) {
    if (date === 'No Due Date') return 'None Set'
    if (date === 'Date Range' || date === 'Today' || date === 'Future' || date === 'Overdue' || date === 'Next Week' || date === 'Rest of Week') return date

    const convertedDate = localContext.$moment(date, 'DD/MM/yyyy')
    if (convertedDate.isSame(localContext.$moment(), 'day')) {
      return 'Today'
    } else if (convertedDate.isSame(localContext.$moment().add(1, 'days'), 'day')) {
      return 'Tomorrow'
    } else if (convertedDate.isBefore(localContext.$moment().add(6, 'days').endOf('day'))) {
      return convertedDate.format('dddd')
    } else if (convertedDate.isSame(localContext.$moment().add(7, 'days'), 'day')) {
      return 'Next ' + convertedDate.format('dddd')
    }
  },
  parseStringForSearch(string) {
    return _.replace(_.toLower(string), /\s+/g, '')
  },

  /**
   * Retrieves objects from stores or caches based on their IDs.
   * @param {Array<string>} ids - An array of object IDs.
   * @returns {Array<object>} An array of objects found by their IDs.
   */
  getObjectsFromIds(ids) {
    let foundObjects = []
    const storeGroups = _.groupBy(ids, utils.storeFromId)
    for (const [store, values] of Object.entries(storeGroups)) {
      if (store) {
        const items = localContext.store.getters[`modules/${store}/getObjects`](values)
        if (Array.isArray(items)) {
          foundObjects.push(...items)
          const foundIds = items.map((el) => el.id)
          _.remove(ids, (id) => foundIds.includes(id))
        }
      } else if (Array.isArray(values) && !_.isNil(values.filter((f) => typeof f === 'string' && f.includes('auth0')))) {
        let users = []
        values.forEach((v) => {
          const user = localContext.store.getters['auxiliary/user/getUser'](v)
          if (!_.isNil(user)) {
            users.push({ id: user.user_id, ...user })
          }
        })
        foundObjects.push(...users)
      }
    }

    // If not found in stores, check the ID cache
    foundObjects.push(..._.values(_.pick(localContext.store.state.application.globalSearch.idCache, ids)))
    // Avoid duplicates
    foundObjects = _.uniqBy(foundObjects, 'id')
    return foundObjects.filter((el) => !el?.deleted)
  },

  /**
   * Creates a duplicate name based on an existing name (e.g., "My Name" -> "My Name (Copy)").
   * @param {string} name - The name to duplicate.
   * @returns {string} The new name with a "Copy" suffix.
   */
  duplicateName(name) {
    if (typeof name != 'string') return
    const match = name.match(/^(.*?)\s*(\(Copy(?:\s+(\d+))?\))$/)

    if (match) {
      const baseString = match[1]
      const count = match[3] ? parseInt(match[3]) : 1

      const newName = baseString + ' (Copy ' + (count + 1) + ')'
      name = newName
    } else name = name + ' (Copy)'

    return name
  },

  /**
   * Replaces an object in an array if it exists, or adds it if it doesn't.
   * @param {Array} arr - The array to check and modify.
   * @param {object} object - The object to replace or add.
   * @param {Function} [matcher=null] - Optional custom matcher function.
   */
  replaceOrAdd(arr, object, matcher = null) {
    if (!Array.isArray(arr) || !object) return
    const foundIndex = arr.findIndex((el) => (typeof matcher === 'function' ? matcher(el, object) : el.id === object.id))
    if (foundIndex >= 0) arr.splice(foundIndex, 1, object)
    else arr.push(object)
  },
  /**
   * Finds the given ref inside the parent components of a given component
   * @param {HTMLElement} component - Initial element.
   * @param {String} ref - Name of the ref to look for.
   */
  findRefInParents(component, ref) {
    const maxIterations = 10 // Failsafe
    let iteration = 1
    let currentComponent = component
    let foundRef = null
    while (currentComponent != null) {
      currentComponent = currentComponent?.$parent
      const found = _.get(currentComponent, `$refs.${ref}`)
      if (found) {
        foundRef = found
        break
      }
      if (iteration >= maxIterations) break
      iteration += 1
    }
    return foundRef
  },
  /**
   * Checks if the value is a plain old boring old object, not an array or some other dumb shit
   */
  isObject(item) {
    return typeof item === 'object' && item !== null && !Array.isArray(item)
  },

  /**
   * If the resource is a folder, fileName/extension will be null, and folderName will contain the name of the folder
   * @param {String} s3path 
   * @returns {String[]} `[orgId, groupId=null, relativePath="", fileName, extension, folderName]`
   */
  retrievePathParts(s3path) {
    if (typeof s3path != 'string') return null
    let orgId = null
    let groupId = null
    let fileName = null
    let relativePath = ""
    let extension = null
    let folderName = null
    const isFolder = s3path.endsWith('/')

    let path = s3path.split('/')

    // Get orgId
    if (path[0].startsWith('org_')) orgId = path.shift()

    // Get groupId
    if (path[0].startsWith('.entityGroups-')) groupId = path.shift()
    
    // Get fileName, extension or folderName
    if (!isFolder) {
      const fileNameParts = path.pop().split('.')
      extension = fileNameParts.pop()
      fileName = fileNameParts.join('.')
    } else {
      path.pop() // remove last, it will be an empty string
      folderName = path.pop()
    }
    
    // Now all that is left is the relative path
    relativePath = path.join('/') + (path.length ? "/" : "")

    return [orgId, groupId, relativePath, fileName, extension, folderName]
  },
  /**
   * Given a file path, return the lodash path so we can access it inside a map/object
   */
  getLodashPath(path) {
    let str = ''
    if (Array.isArray(path)) {
      const { length } = path

      path
        .filter((el) => !!el)
        .forEach((el, i) => {
          el = el.replace(/"/g, '\\"')
          if (el.includes('.')) str += `["${el}"]`
          else if (i === length - 1) str += '.' + el
          else if (i !== 0) str += '.' + el + '/'
          else str += el + '/'
        })
    } else if (typeof path === 'string') {
      const { length } = path.split('/')

      path
        .split('/')
        .filter((el) => !!el)
        .forEach((el, i) => {
          el = el.replace(/"/g, '\\"')
          if (el.includes('.')) str += `["${el}"]`
          else if (i === length - 1) str += '.' + el
          else if (i !== 0) str += '.' + el + '/'
          else str += el + '/'
        })
    }

    return str
  },
  removeQueryParameter(...params) {
    const query = Object.assign({}, $nuxt.$route.query)
    params.forEach((param) => {
      if (query[param]) delete query[param]
    })
    $nuxt.$router.replace({ query })
  },
}

export default (context, inject) => {
  localContext = context
  inject('utils', utils)
}
