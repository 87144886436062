export default {
  methods: {
    async downloadAttachment(data) {
      let path = ''
      if (typeof data === 'string' && this.$utils.isUUID(data)) {
        let doc = this.$store.getters['auxiliary/documents/getById'](data) || null
        if (!doc) doc = (await this.$store.dispatch('auxiliary/documents/getDocumentById', data)).data // Try fetch document if it isn't found in selected group documents
        path = doc?.path
      } else if (typeof data === 'object') {
        path = data.path
      }
      await this.$store.dispatch('modules/fileManager/download', { paths: [path] })
    },
    downloadS3Object(response, filenameOverride) {
      if (_.isNil(response) || !'data' in response) return
      const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }))
      const link = document.createElement('a')
      const contentDisposition = response.headers['content-disposition']

      let fileName = 'unknown'
      if (contentDisposition) {
        let fileNameMatch = contentDisposition.match(/filename="(.+)"/)
        if (!fileNameMatch) {
          fileNameMatch = contentDisposition.match(/filename=(.+)/)
          if (fileNameMatch.length === 2) {
            fileName = fileNameMatch[1]
          }
        } else if (fileNameMatch.length === 2) {
          fileName = fileNameMatch[1]
        }
      }

      link.href = url
      link.setAttribute('download', filenameOverride ? filenameOverride : fileName)
      document.body.appendChild(link)

      link.click()
      link.remove()
      window.URL.revokeObjectURL(url)
    },
    openS3Object(response) {
      if (!'data' in response) return

      const link = document.createElement('a')
      const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }))
      return url

      let params = ''
      let i = 0
      for (const [key, val] of Object.entries(response.config.params)) {
        params += (i === 0 ? '?' : '&') + `${key}=${val}`
        i++
      }

      link.href = response.config.baseURL + response.config.url + params
      link.target = '_blank'
      document.body.appendChild(link)

      link.click()
      link.remove()
    },
    /**
     * Downloads a JS File object
     * @param {File} file the file to download
     */
    downloadFileObject(file) {
      const url = window.URL.createObjectURL(file)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', file.name)
      document.body.appendChild(link)

      link.click()
      link.remove()
      window.URL.revokeObjectURL(url)
    },
    /**
     * Creates a mock File
     * @param {*} fileName
     * @param {*} fileType
     * @returns
     */
    createMockFile(fileName, fileType) {
      return new File([new Uint8Array(0)], fileName, { type: fileType })
    },
    /**
     * Base64 encode JS File object
     * @param {File} file JS File
     * @returns {Promise<string>} Promise<string>
     */
    async getBase64FromFile(file) {
      if (Array.isArray(file)) file = file.at(0)

      return new Promise((resolve, reject) => {
        let reader = new FileReader()

        reader.onloadend = (e) => resolve(e.target.result)
        reader.onerror = (e) => {
          resolve(null)
        }

        // Read file
        if (!file) reject()
        reader.readAsDataURL(file)
      })
    },
  },
}
