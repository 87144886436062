import _ from 'lodash'

export default {
  props: {
    /**
     * Filter options (will override the header filter properties)
     * Array[] {
     *  text: "Group One",
     *  value: "group1",
     *  options: [
     *    {
     *      filter: (tableRow) => return Boolean ,
     *      text: "Filter Option One",
     *      value: "filterOption1"
     *    }
     *  ]
     * }
     */
    /**
     * { page: this.currentPage,
     *  segment: segment: _.get(_filters.find(el => _.has(el,'segment')),'segment',''),
     *  filter: {filterId: name.toLowerCase(), filterName: name, filterOptions: _filters ? _filters : []
     * }
     */
    /**
     * Give this prop to tables on a page which has multiple tables with filters
     *  (so that the filters don't apply to both tables)
     *
     * This can be overriden by a computed/data property with the name "_filterId" (if you don't have access to props, eg within a nuxt page)
     */

    filterId: {
      type: String,
      default: '',
    },
  },
  mounted () {
    this.$store.dispatch('auxiliary/filters/debouncedParseURL')
  },
  methods: {
    normalizeObject(obj) {
      return _.mapValues(obj, (value) => {
        if (_.isArray(value) && value.length === 1) {
          // If it's an array with one element, convert to that single element
          return value[0]
        }
        return value
      })
    },
    useStickyVariables(filters) {
      if (_.isNil(filters) || filters.length === 0) return false
      //myTask Sticky
      if (this.activeFilter?.filter?.filterId == 'myTasks') {
        return _.map(filters, 'value').includes(this.$auth.$state.user.sub)
      }
      return _.get(this.activeFilter, 'filter.sticky', false)
    },
    applySavedFilter(filterOptions, segments, filterName, filterId, sticky = false) {
      this.$store.commit('auxiliary/filters/setActiveFilter', {
        page: this.currentPage,
        segment: segments && segments.length > 0 ? segments : undefined,
        filter: { filterId: filterId, filterName: filterName, filterOptions: filterOptions, sticky: sticky },
      })

      let newQuery = Object.assign(_.mapValues(_.groupBy(filterOptions, 'group'), (group) => group.map((el) => el.value)))

      newQuery = {
        ...newQuery,
        segments: segments && segments.length > 0 ? segments : undefined,
        filterName: filterName,
      }
      if (!_.isEqual(this.normalizeObject(this.$route.query), this.normalizeObject(newQuery))) {
        this.$router.replace({ query: newQuery })
      }
      this.$store.commit('auxiliary/filters/setActiveSegmentsPage', { page: this.currentPage, value: segments && segments.length > 0 ? segments : undefined, setURL: false })
    },
  },
  computed: {
    savedFilterNames() {
      const allFilters = this.$store.getters['auxiliary/filters/getByPage'](this.currentPage)
      if (!allFilters || !Array.isArray(allFilters.filters)) return []

      return allFilters.filters.map((filter) => filter.filterName) || []
    },
    currentPage() {
      const page = this.$store.getters['auxiliary/filters/getFilterPage']
      return this._filterId ? `${page}_${this._filterId}` : this.filterId ? `${page}_${this.filterId}` : page
    },
    activeSegment() {
      return _.get(this.activeFilter, 'segment', null)
    },
    activeFilter() {
      return this.$store.getters['auxiliary/filters/getActiveFilterByPage'](this.currentPage)
    },
    filters: {
      get() {
        return _.get(this.activeFilter, 'filter.filterOptions', [])
      },
      set(_filters) {
        this.$store.commit('auxiliary/filters/setActiveFilter', {
          page: this.currentPage,
          segment: this.activeSegment,
          filter: { filterId: this.useStickyVariables(_filters) ? this.activeFilter.filter.filterId : 'custom', filterName: undefined, filterOptions: _filters, sticky: _.get(this.activeFilter, 'filter.sticky', false) },
        })
        // Set query
        let routeQuery = Object.assign(
          _.cloneDeep(this.$route.query),
          _.mapValues(_.groupBy(_filters, 'group'), (group) => {
            if (group.length === 1) {
              return group[0].value
            } else {
              return _.map(group, 'value')
            }
          })
        )
        // if the filters no longer contain at least 1 key, remove it from the URL
        // Dont touch segments they are different
        Object.keys(routeQuery).forEach((key) => {
          if (!Object.keys(_.groupBy(_filters, 'group')).includes(key)) {
            if (key != 'segments') {
              delete routeQuery[key]
            }
          }
        })
        //Remove the name if someone has clicked a filter
        delete routeQuery.filterName
        // Only navigate if query is going to change
        if (!_.isEqual(this.normalizeObject(this.$route.query), this.normalizeObject(routeQuery))) {
          this.$router.replace({ query: routeQuery })
        }
      },
    },
    groupedFilters() {
      if (this.filters.length === 0) return null
      let groupedData = {}
      Object.entries(_.groupBy(this.filters, 'group')).forEach(([key, value]) => {
        if (key != 'undefined') {
          if (!Array.isArray(value)) {
            groupedData[key] = value.map((el) => el.value)
          } else {
            //gotta unpack values
            groupedData[key] = _.flatMapDeep(value)
          }
        }
      })
      return groupedData
    },
  },
}
